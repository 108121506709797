import { Typography } from "@mui/material";
import BoxDX from "../layout/boxdx";
import ProgressBarDX from "../layout/progressbardx";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useState } from "react";

const UtilizationBox = (props: any) => {
  const { t } = useTranslation();

  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <Typography fontSize={24} fontWeight="bold" sx={{ m: 1 }}>
        {t("List of Areas")}
      </Typography>
      <AreaListItem items={props.warehouseData?.areas} />
    </BoxDX>
  );
};

const AreaListItem = (props: any) => {
  const { items } = props;

  return (
    items.map((item: any) =>
      <ListItem item = {item} name = {item.areaName} items = {<RackListItem items={item.racks} />} showButton = {item.racks.length > 0} />
    )
  );
};

const RackListItem = (props: any) => {
  const { items } = props;

  return (
    items.map((item: any) =>
      <ListItem item = {item} name = {item.rackName} items = {<LocationListItem items={item.locations} />} showButton = {item.locations.length > 0} />
    )
  );
};

const LocationListItem = (props: any) => {
  const { items } = props;

  return (
    items.map((item: any) =>
      <ListItem item = {item} name = {item.rackLocationName} items = {null} showButton = {false} />
    )
  );
};

const ListItem = (props: any) => {
  const { item, name, items, showButton } = props;
  const {i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const ratio = item.usedCapacity/item.totalCapacity;

  return (
    <>
      <BoxDX
        display="flex"
        flexDirection="row"
        sx={{
          width: "100%",
          height: 40,
          px: 2,
          alignItems: "center",
          borderRadius: 5,
          backgroundColor: open ? "#F0E5FF" : "transparent",
        }}
      >
        <Typography fontWeight="bold" sx={{ mx: 1, width: "100%" }}>
          {name}
        </Typography>
        <BoxDX
          display="flex"
          flexDirection="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Typography
            textAlign={"end"}
            sx={{ mx: 1, minWidth: 140 }}
          >{`${item.usedCapacity}/${item.totalCapacity} (CBM)`}</Typography>
          <BoxDX display="flex">
            <ProgressBarDX color="#6800F4" value={isNaN(ratio) ? 0 : ratio * 100} width={450} />
          </BoxDX>
          <Typography
            fontWeight="bold"
            sx={{
              ml: i18n.language === "ar" && showButton ? 0 : 5,
              mr: i18n.language === "en" && showButton ? 0 : 5,
              width: 60,
            }}
          >
            {`${isNaN(ratio) ? 0 : ratio * 100}%`}
          </Typography>
          {showButton > 0 && (
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </BoxDX>
      </BoxDX>
      {items ? <Collapse in={open} sx={{ p: 1 }}>
        {items}
      </Collapse> : <></>}
    </>
  )
};

export default UtilizationBox;
