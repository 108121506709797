import { Typography } from "@mui/material";
// import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import GridDX from "../layout/griddx";
import Loading from "../layout/loading";
import { BarChart } from "@mui/x-charts";
import BoxDX from "../layout/boxdx";
import SelectListDX from "../controls/selectlistdx";
import useWarehouseService from "../../shared/services/warehouseservice";
import useDashboardService from "../../shared/services/dashboardservice";
import { useAuthContext } from "../../context/authcontext";
import { useTenantContext } from "../../context/tenantcontext";

const AnnualUtilizationChart = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { tenantId }= useTenantContext();
  const { setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const { getAnnualUtilization } = useDashboardService();
  const xLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const valueFormatter = (value: any) => `${value}%`;

  const [warehouses, setWarehouses] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [series, setSeries] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    const operation = userData.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    operation
      .then((res) => {
          setWarehouses(
            res.map((item: any) => {
              return { text: item.name, value: item.warehouseId };
            }))
        })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true)
    warehouseId && getAnnualUtilization(warehouseId)
      .then((res) => {
        setData(res);
        let newSeries = [];
        newSeries.push({ data: res.map((item: any) => item.utilization), id: "pvId", valueFormatter });
        setSeries(newSeries);
        })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, [warehouseId]);

  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        p: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
          {t("Space Utilization")}
        </Typography>
        <BoxDX mt={1} width={250}>
          <SelectListDX
            label={t("Warehouse")}
            name="warehouseId"
            items={warehouses}
            value={warehouseId}
            onChange={(e: any) => setWarehouseId(e.target.value)}
          />
        </BoxDX>
      </BoxDX>
      <GridDX
        item
        xs={7}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      ></GridDX>
      <GridDX
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading && data ? (
          <div style={{ minHeight: "200px", position: "relative" }}>
            <Loading
              styles={{
                height: "100%",
                width: "100%",
                left: 0,
                position: "absolute",
              }}
            />
          </div>
        ) : (
          <BarChart
            height={350}
            series={series}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
            yAxis={[{ max: 100, label: t("capacity (%)") }]}
            sx={{ direction: "ltr" }}
            rightAxis={i18n.language === "en" ? null : [{ max: 100, label: t("capacity (%)") }]}
            leftAxis={i18n.language === "en" ? [{ max: 100, label: t("capacity (%)") }] : null}
            colors={["#AD70FF"]}
            {...props}
          />
        )}
      </GridDX>
    </BoxDX>
  );
};

export default AnnualUtilizationChart;
