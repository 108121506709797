import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import useCityService from "../../../shared/services/cityservice";
import useWarehouseCarrierService from "../../../shared/services/warehousecarrierservice";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import CheckBoxDX from "../../controls/checkboxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import TypographyDX from "../../layout/typographydx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useAuthContext } from "../../../context/authcontext";
import { useTenantContext } from "../../../context/tenantcontext";

const WarehouseCarrierForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const CarrierId = state?.carrierId;
  const handleClose = () => {
    navigate(-1);
  };
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { getCities } = useCityService();
  const { getWarehouses } = useWarehouseService();
  const {  getWarehouseCarrierById, addWarehouseCarrier, updateWarehouseCarrier } = useWarehouseCarrierService();
  const { integrationMethodTypes } = TypeTranslator();

  const defaultData = {
    cityId: "",
    warehouseId: "",
    name: "",
    code: "",
    email: "",
    pocName: "",
    phone: "",
    integrationMethodTypeId: "",
    trackingUrl: "",
    labelDensity: "",
    enableZebraPrint: false,
    enableMultiPrint: false,
    enableReturnSupport: false,
    enableMultiparcelSupport: false,
    carrierSupported: false,
  };

  const [data, setData] = useState<any>(defaultData);
  const [cities, setCities] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    if (!data.code) newErrors["code"] = "Code is required";
    if (!data.warehouseId) newErrors["warehouse"] = "Warehouse is required";
    if (!data.cityId) newErrors["city"] = "City is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const operation = CarrierId
        ? updateWarehouseCarrier(data)
        : addWarehouseCarrier(data);
      operation
        .then(() => {
          setInfo(
            CarrierId
              ? t("Carrier updated successfully")
              : t("Carrier added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getLists = async () => {
    const p1 = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    const p2 = getCities();

    Promise.all([p1, p2])
      .then(([houses, citys]) => {
        setWarehouses(
          houses.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
        setCities(
          citys.map((city: any) => {
            return { text: city.cityName, value: city.cityId };
          })
        );
      })
      .catch((err) => setError(err));
  };
  
  useEffect(() => {
    if (CarrierId){
      setIsLoading(true);
      getWarehouseCarrierById(CarrierId)
        .then((res) => {
          setData(res);
          getLists();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else getLists();
  }, [tenantId]);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Carrier Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading sx={{ width: "100%", height: "100%"}} />}
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Code")}
            name="code"
            value={data?.code}
            onChange={handleInputChange}
            error={errors["code"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
              label={t("Select Warehouse")}
              name="warehouseId"
              items={warehouses}
              value={data.warehouseId}
              onChange={handleInputChange}
              error={errors["warehouse"]}
            />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("POC Name")}
            name="pocName"
            value={data?.pocName}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Email")}
            name="email"
            value={data?.email}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Phone")}
            name="phone"
            value={data?.phone}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Tracking URL")}
            name="trackingUrl"
            value={data?.trackingUrl}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Label Density")}
            name="label_density"
            value={data?.label_density}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("City")}
            name="cityId"
            items={cities}
            value={data.cityId}
            onChange={handleInputChange}
            error={errors["city"]}
          />  
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
              label={t("Integration Method Type")}
              name="integrationMethodTypeId"
              items={integrationMethodTypes}
              value={data.integrationMethodTypeId}
              onChange={handleInputChange}
            />
        </GridDX>
        <GridDX item xs={0} md={8}>
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Print Setting")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Zebra Print")}
            checked={data?.enableZebraPrint}
            onChange={(e: any) => {
              setData({
                ...data,
                enableZebraPrint: !data.enableZebraPrint,
              });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Multi-Print")}
            checked={data?.enableMultiPrint}
            onChange={(e: any) => {
              setData({
                ...data,
                enableMultiPrint: !data.enableMultiPrint,
              });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Support")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Support Return")}
            checked={data?.enableReturnSupport}
            onChange={(e: any) => {
              setData({
                ...data,
                enableReturnSupport: !data.enableReturnSupport,
              });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Support Multiparcel")}
            checked={data?.enableMultiparcelSupport}
            onChange={(e: any) => {
              setData({
                ...data,
                enableMultiparcelSupport: !data.enableMultiparcelSupport,
              });
            }}
          />
        </GridDX>
        <GridDX item xs={0} md={8}>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Supported Carrier")}
            checked={data?.carrierSupported}
            disabled={!data?.enableMultiparcelSupport}
            onChange={(e: any) => {
              setData({
                ...data,
                carrierSupported: !data.carrierSupported,
              });
            }}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default WarehouseCarrierForm;
