import { t } from "i18next";
import { useState, useEffect } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useWarehouseRackLocationService from "../../../../shared/services/warehouseracklocationservice";
import useWarehouseRackService from "../../../../shared/services/warehouserackservice";
import TypeTranslator from "../../../../shared/typetranslator";
import CheckBoxDX from "../../../controls/checkboxdx";
import SelectListDX from "../../../controls/selectlistdx";
import TextFieldDX from "../../../controls/textfielddx";
import BoxDX from "../../../layout/boxdx";
import GridDX from "../../../layout/griddx";
import ItemBox from "../../../units_components/itembox";
import useStorageTypeService from "../../../../shared/services/storagetypeservice";

const WarehouseRackLocationDetails = (props: any) => {
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;

  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const warehouseRackLocationId = state?.warehouseRackLocationId;
  const { setInfo, setError } = useNotificationContext();
  const { getStorageTypes } = useStorageTypeService();
  const { getWarehouseRacksByWarehouseId } = useWarehouseRackService();
  const { addWarehouseRackLocation, updateWarehouseRackLocation, getWarehouseRackLocationById } =
    useWarehouseRackLocationService();
  const { warehouseCategories, rackSizes, locationTypes, storageTypes } = TypeTranslator();

  const defaultValues = {
    warehouseRackId: null,
    warehouseRackLocationId: 0,
    name: "",
    code: "",
    locationDetailTypeId: null,
    storageTypeId: null,
    warehouseCategoryTypeId: null,
    rackSizeTypeId: null,
    heightInCm: 0,
    widthInCm: 0,
    lengthInCm: 0,
    usedCBM: 0,
    isActive: false
  };

  const [rackLocationData, setRackLocationData] = useState<any>(defaultValues);
  const [storageTypeList, setStorageTypeList] = useState<any>([]);
  const [rackList, setRackList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [rackLocationData]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setRackLocationData({
      ...rackLocationData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!rackLocationData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!rackLocationData.code) {
      newErrors["code"] = t("Code is required");
    }
    if (!rackLocationData.warehouseRackId) {
      newErrors["warehouseRackId"] = t("Rack is required");
    }
    if (!rackLocationData.warehouseCategoryTypeId) {
      newErrors["warehouseCategoryTypeId"] = t("Category is required");
    }
    if (!rackLocationData.storageTypeId) {
      newErrors["storageTypeId"] = t("Storage Type is required");
    }
    if (!rackLocationData.rackSizeTypeId) {
      newErrors["rackSizeTypeId"] = t("Size is required");
    }
    if (!rackLocationData.locationDetailTypeId) {
      newErrors["locationDetailTypeId"] = t("Location Type is required");
    }
    if (rackLocationData.widthInCm <= 0) 
    {
      newErrors["widthInCm"] = t("Width is required");
    }
    if (rackLocationData.heightInCm <= 0) 
    {
      newErrors["heightInCm"] = t("Height is required");
    }
    if (rackLocationData.lengthInCm <= 0) 
    {
      newErrors["lengthInCm"] = t("Length is required");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseRackLocationId
        ? updateWarehouseRackLocation(rackLocationData)
        : addWarehouseRackLocation(rackLocationData);
      operation
        .then(() => {
          warehouseRackLocationId
            ? setInfo(t("Warehouse Rack Location updated successfully"))
            : setInfo(t("Warehouse Rack Location added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const getAreas = async () => {
    const p1 = getWarehouseRacksByWarehouseId(warehouseId);
    const p2 = getStorageTypes();

    setIsLoading(true);
    Promise.all([p1, p2])
      .then(([racks, types]) => {
        setRackList(racks.map((rack: any) => {
          return {
            text: rack.name,
            value: rack.warehouseRackId
          };
        }));
        setStorageTypeList(types.map((type: any) => {
          return {
            text: type.name,
            value: type.storageTypeId
          };
        }));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const getData = async () => {
    if (warehouseRackLocationId) {
      setIsLoading(true);
      getWarehouseRackLocationById(warehouseRackLocationId)
        .then((RackLocation) => {
          setRackLocationData(RackLocation);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    getAreas();
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={rackLocationData?.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Code")}
              name="code"
              value={rackLocationData?.code}
              onChange={handleInputChange}
              error={errors["code"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Rack")}
              name="warehouseRackId"
              value={rackLocationData?.warehouseRackId}
              items={rackList}
              onChange={handleInputChange}
              error={errors["warehouseRackId"]}
              //shrink
              InputLabelProps={{
                shrink: rackLocationData?.warehouseRackId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Category")}
              name="warehouseCategoryTypeId"
              value={rackLocationData?.warehouseCategoryTypeId}
              items={warehouseCategories}
              onChange={handleInputChange}
              error={errors["warehouseCategoryTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: rackLocationData?.warehouseCategoryTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX><GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Location Details")}
              name="locationDetailTypeId"
              value={rackLocationData?.locationDetailTypeId}
              items={locationTypes}
              onChange={handleInputChange}
              error={errors["locationDetailTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: rackLocationData?.locationDetailTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Size")}
              name="rackSizeTypeId"
              value={rackLocationData?.rackSizeTypeId}
              items={rackSizes}
              onChange={handleInputChange}
              error={errors["rackSizeTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: rackLocationData?.rackSizeTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Height")} (${t("cm")})`}
            name="heightInCm"
            value={rackLocationData?.heightInCm}
            onChange={handleInputChange}
            type="number"
            error={errors["heightInCm"]}
          />
        </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={`${t("Width")} (${t("cm")})`}
              name="widthInCm"
              value={rackLocationData?.widthInCm}
              onChange={handleInputChange}
              type="number"
              error={errors["widthInCm"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              label={`${t("Length")} (${t("cm")})`}
              name="lengthInCm"
              value={rackLocationData?.lengthInCm}
              onChange={handleInputChange}
              type="number"
              error={errors["lengthInCm"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Storage Type")}
              name="storageTypeId"
              value={rackLocationData?.storageTypeId}
              items={storageTypeList}
              onChange={handleInputChange}
              error={errors["storageTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: rackLocationData?.storageTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={8}>
          <CheckBoxDX
            name={"isActive"}
            label={t("Active")}
            checked={rackLocationData?.isActive}
            onChange={handleInputChange}
          />
        </GridDX>
        </GridDX>
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseRackLocationDetails;
