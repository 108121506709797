import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DateSorter, inboundRequestStatusColor, DATE_FORMAT } from "../../../shared/globals";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import TypeTranslator from "../../../shared/typetranslator";
import { useTenantContext } from "../../../context/tenantcontext";


const RequestList = () => {
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setAddRecordfn } = useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { getInboundRequests, archiveInboundRequest } =
    useInboundRequestService();
  const { setInfo, setError } = useNotificationContext();
  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getInboundDeliveryTypeValue,
    getInboundRequestStatusValue,
    inboundRequestStatuses,
  } = TypeTranslator();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: `${t("Request Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "inboundDeliveryTypeId",
      headerName: `${t("Inbound Delivery Type")}`,
      flex: 1,
    },
    {
      field: "scheduleDate",
      headerName: `${t("Schedule Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "pocName",
      headerName: `${t("POC Name")}`,
      flex: 1,
    },
    {
      field: "pocPhone",
      headerName: `${t("POC Phone")}`,
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      headerAlign: "left",
      align: "left"
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                inboundRequestStatusColor.light[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ] || "white",
              color: `${
                inboundRequestStatusColor.regular[
                  inboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];
  // Check if userType is 'S'
  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    columns.splice(1, 0, customerNameColumn);
  }

  const getData = () => {
    setIsLoading(true);
    const operation = userData.userType === "S" ? getInboundRequests(tenantId) : getInboundRequests();
    operation
      .then((response: any) => {
        const updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.requestDate).format(DATE_FORMAT),
          scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
          inboundDeliveryTypeId: getInboundDeliveryTypeValue(
            request.inboundDeliveryTypeId
          ),
          status: getInboundRequestStatusValue(request.status),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.requestDate).isBefore(moment(b.requestDate)) ? 1 : -1
        );
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDelete = (id: number) => {
    setRows(rows.filter((row: any) => row.inbound_request_id !== id));
    setInfo(t("Inbound request deleted successfully"));

    setIsLoading(true);
    archiveInboundRequest(id)
      .then((response: any) => {
        setInfo(t("Inbound request archived successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEdit = (data: any) => {
    navigate(`/inbounddetails`, {
      state: {
        requestId: data?.inboundRequestId,
      },
    });
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        inboundDeliveryTypeId,
        scheduleDate,
        status,
        pocPhone,
        pocName,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          getInboundDeliveryTypeValue(inboundDeliveryTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
          // status.toLowerCase().includes(value.toLowerCase()) ||
          pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
          pocName.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    getData();
  }, [i18n.language, tenantId]);

  return (
    <ListPageDX
      listTitle={t("Inbound Request")}
      name={t("Request")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.inboundRequestId}
      isLoading={isLoading}
      onEdit={onEdit}
      editLabel={t("Edit")}
      onDelete={onDelete}
      deleteLabel={t("Archive")}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={true}
      filterByDate={filterByDate}
    />
  );
};

export default RequestList;
