import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { useNotificationContext } from "../../context/notificationcontext";
import ListPageDX from "../../components/business/listpagedx";
import useWarehouseTimeslotService from "../../shared/services/warehousetimseslotservice";
import SelectListDX from "../../components/controls/selectlistdx";
import BoxDX from "../../components/layout/boxdx";
import GridDX from "../../components/layout/griddx";
import useWarehouseService from "../../shared/services/warehouseservice";
import moment from "moment";
import { dateWithTime } from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";
import { useTenantContext } from "../../context/tenantcontext";

const WarehouseTimeslots = () => {
  const { t, i18n } = useTranslation();
  const {  setAddRecordfn } =
    useOutletContext() as any;
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { getWarehouses } = useWarehouseService();
  const { getWarehouseTimeslotsByWarehouseId, archiveWarehouseTimeslot } = useWarehouseTimeslotService();
  const { setInfo, setError } = useNotificationContext();

  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>(null);
  const [warehouses, setWarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "startTime",
      headerName: `${t("From")}`,
      minWidth: 150,
      valueFormatter(params) {
          const date = dateWithTime(params.value);
          return (moment(date).format("LT"));
      },
    },
    {
      field: "endTime",
      headerName: `${t("To")}`,
      minWidth: 150,
      valueFormatter(params) {
        const date = dateWithTime(params.value);
        return (moment(date).format("LT"));
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [warehouseId]); 

  useEffect(() => {
    getData();
    getWarehouseList();
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  useEffect(() => {
    getWarehouseList();
  }, [tenantId]); 

  const getData = () => {
    if (warehouseId) {
      setIsLoading(false);
      getWarehouseTimeslotsByWarehouseId(warehouseId)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    };

  const getWarehouseList = async () => {
    const operation = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    operation
      .then((res) => {
        setWarehouses(
          res.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const onEdit = (data: any) => {
    navigate("/warehousetimeslotdetails", {
      state: {
        timeslotId: data.warehouseTimeslotId,
      },
    });
  };
  const toCreate = () => {
    navigate("/warehousetimeslotdetails", {
      state: {},
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveWarehouseTimeslot(id)
      .then(() => {
        setInfo(t("Timeslot has been archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.startTime.toLowerCase().includes(value.toLowerCase()) ||
        row.endTime.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setWarehouseId(value);
  };

  return (
    <GridDX sx= {{ flexDirection: "column", alignItems: "flex-end", overflow: 'hidden'}} >
      <GridDX
        item
        xs={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        mt={1}
      >
        <BoxDX width={350}>
          <SelectListDX
            label={t("Select Warehouse")}
            name="warehouseId"
            items={warehouses}
            value={warehouseId}
            onChange={handleInputChange}
          />
        </BoxDX>
      </GridDX>
      <ListPageDX
        listTitle={t("WarehouseTimeslots")}
        name={t("Warehouse Timeslots")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.warehouseTimeslotId}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        deleteLabel={t("Archive")}
        editLabel={t("Preview")}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={false}
        showSelect={false}
      />
    </GridDX>
  );
};

export default WarehouseTimeslots;
