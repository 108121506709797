import AdminDashboard from "../components/pages/dashboard/admindashboard/admindashboard";
import CustomerDashboard from "../components/pages/dashboard/customerdashboard";
import { useAuthContext } from "../context/authcontext";

const Dashboard = () => {
  const { userData } = useAuthContext();

  return (
    userData.userType !== "C" ? <AdminDashboard/> : <CustomerDashboard/>
  );
};

export default Dashboard;