import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import ButtonDX from "../../controls/buttondx";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { read, utils } from "xlsx";
import CheckBoxDX from "../../controls/checkboxdx";
import useSKUService from "../../../shared/services/skuservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import ProgressBarDX from "../../layout/progressbardx";
import TextFieldDX from "../../controls/textfielddx";
import TypeTranslator from "../../../shared/typetranslator";
import useCustomerService from "../../../shared/services/customerservice";
import SelectListDX from "../../controls/selectlistdx";
import { useTenantContext } from "../../../context/tenantcontext";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const SKUBulkForm = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { setInfo, setError } = useNotificationContext();
  const { tenantId } = useTenantContext();
  const { getCustomers } = useCustomerService();
  const { addSKU, bulkAddSKU, deleteStock } = useSKUService();
  const { unitTypes } = TypeTranslator();

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<null | File>(null);
  const [imagesPath, setImagesPath] = useState<null | FileList>(null);
  const [Customers, setCustomers] = useState([]);
  const [CustomerId, setCustomerId] = useState<any>(null);
  const [rowCount, setRowCount] = useState(0);
  const [savedSKUs, setSavedSKUs] = useState(0);
  const [overWrite, setOverWrite] = useState(false);
  const [withImages, setWithImages] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState("");

  const handleClick = (event: any) => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  const handleCheckBox = (event: any) => {
    const { name, checked } = event.target;
    if (name === "images") setWithImages(checked);
    else if (name === "overwrite") setOverWrite(checked)
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    if (event.target.files) setFile(event.target.files[0]);
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    if (event.target.files) setFile(event.target.files[0]);
  };

  const onChangeHandler = async (event: any) => {
    if (event.target.files) setFile(event.target.files[0]);
  };

  const onDirectoryChange = async (event: any) => {
    if (event.target.files) setImagesPath(event.target.files);
  };

  const onSave = async () => {
    if (!file) {
      setError(t("Please select a file"));
      return;
    }
    if (withImages && !imagesPath) {
      setError(t("Please select a folder"));
      return;
    }

    setIsSaving(true);
    const ab = await file?.arrayBuffer();
    const wb = read(ab); //parse data
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const readData = utils.sheet_to_json(ws); // generate objects
    setRowCount(readData.length);
    let data: any = []; 
    readData.forEach((sku: any) => {
      const { UOM, ManagementType, IsPerishable, EnableBatch, EnableShelfLife, EnableStockTakeCycle, ...rest } = sku;
      const id = getUOMId(UOM);
      if (id) data.push({
        ...rest,
        isPerishable: (IsPerishable ?? "").toLowerCase() === "yes",
        enableBatch: (EnableBatch ?? "").toLowerCase() === "yes",
        enableShelfLife: (EnableShelfLife ?? "").toLowerCase() === "yes",
        enableStockTakeCycle: (EnableStockTakeCycle ?? "").toLowerCase() === "yes",
        UomTypeId: id,
        SerializedManagementTypeId: (EnableStockTakeCycle ?? "").toLowerCase() === "USN" ? 1 : 2,
      });
      else {
        setErrors("One or more rows had unknown UOM");
        setShowErrors(true);
      }
    });

    if (overWrite) {
      const deleteOperation = userData.userType !== "C" ? deleteStock(CustomerId) : deleteStock(userData.customerId);
      await deleteOperation;
    }

    const operation = userData.userType !== "C" ? bulkAddSKU(data, CustomerId) : bulkAddSKU(data, userData.customerId);

    operation
      .then((res) => {
        setInfo(t("SKUs added successfully"));
        if ( res === "" && !showErrors) props.handleClose(true);
        else {
          setErrors(errors + res);
          setShowErrors(true);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const getImage = (code: string) => {
    for (let fileIndex in imagesPath) {
      if (fileIndex !== "item" && fileIndex !== "length") {
        const index = parseInt(fileIndex);
        if (imagesPath[index].name.split(".")[0] === code.toString())
          return index;
      }
    }
    return -1;
  };

  const getUOMId = (name: string) => {
    for (let unit of unitTypes) {
      if (unit?.text.toLowerCase() === name.toLowerCase()) return unit?.value;
    }
    return null
  };

  const saveWithImages = async (fileData: any) => {
    let err = "";
    let saved = 0;
    for (let item in fileData) {
      const sku = fileData[item];
      const index = parseInt(item) + 1;
      if (!sku.Name || !sku.Code) {
        err += `\nrow ${index} has no name or code`;
      } else {
        const formData = new FormData();
        formData.append("code", sku.Code);
        formData.append("name", sku.Name);
        formData.append("customerId", userData.customerId || props.customerId);
        formData.append("specification", sku.Specification);
        formData.append("isPerishable", sku.isPerishable);

        const imageIndex = getImage(sku.Code);
        if (imageIndex !== -1)
          formData.append("picture", imagesPath?.item(imageIndex) as File);
        else {
          err += `\nrow ${index} with code ${sku.Code} has no image`;
        }
        await addSKU(formData);
        saved += 1;
      }
      setErrors(err);
      setSavedSKUs(saved);
    }
    setShowErrors(err.length > 0);
  };

  const getCustomerList = async () => {
    getCustomers(tenantId)
      .then((res) => {
        setCustomers(
          res.map((item: any) => {
            return { text: item.fullname, value: item.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };
  
  useEffect(()=>{
    if ( userData.userType !== "C" ) getCustomerList();
  }, [])

  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose()}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      sx={{ direction: i18n.dir(), ...props.sx }}
    >
      <DialogTitle display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
        <TypographyDX variant="h5" fontWeight="bold">
          {t("SKU Import")}
        </TypographyDX>
        {userData.userType !== "C" && <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Select Customer")}
            name="customerId"
            items={Customers}
            value={CustomerId}
            onChange={(e: any) => setCustomerId(e.target.value)}
          />
        </GridDX>}
      </DialogTitle>
      <DialogContent>
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
          {errors === "" ? (
            <>
              <GridDX
                item
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mx: 2, height: "100%" }}
              >
                {file ? (
                  <BoxDX display="flex" sx={{ width: 600 }}>
                    <Chip
                      label={file.name}
                      color="primary"
                      onDelete={() => setFile(null)}
                    />
                  </BoxDX>
                ) : (
                  <BoxDX
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    component="fieldset"
                    sx={{
                      border: "1px dashed #c6c6c6",
                      borderRadius: 1,
                      width: 600,
                      height: 150,
                    }}
                  >
                    <BoxDX
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        {t("Drag and Drop File here or")}
                      </Typography>
                      <ButtonDX variant="text" onClick={handleClick}>
                        {t("Browse")}
                      </ButtonDX>
                    </BoxDX>
                    <BoxDX sx={{ display: "none" }} justifyContent="center">
                      <input
                        ref={inputRef}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        name="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                    </BoxDX>
                  </BoxDX>
                )}
              </GridDX>
              {/* <GridDX
                item
                xs={12}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <BoxDX
                  display="flex"
                  sx={{ width: 600, justifyContent: "space-between" }}
                >
                  <CheckBoxDX
                    name={"images"}
                    label={t("Upload with images")}
                    checked={withImages}
                    onChange={handleCheckBox}
                  />
                  {withImages &&
                    (imagesPath ? (
                      <Chip
                        label={imagesPath[0].webkitRelativePath.split("/")[0]}
                        onDelete={() => setImagesPath(null)}
                      />
                    ) : (
                      <ButtonDX variant="contained" component="label">
                        {t("Choose Directory")}{" "}
                        <input
                          type="file"
                          hidden
                          webkitdirectory=""
                          multiple
                          onChange={onDirectoryChange}
                        />
                      </ButtonDX>
                    ))}
                </BoxDX>
                {withImages && (
                  <Typography
                    textAlign={"end"}
                    fontSize={12}
                    sx={{ width: 600 }}
                  >
                    *{t("sku img import msg")}
                  </Typography>
                )} 
              </GridDX>*/}
              <GridDX
                item
                xs={12}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <BoxDX
                  display="flex"
                  sx={{ width: 600, justifyContent: "space-between", alignItems: "center" }}
                >
                  <CheckBoxDX
                    name={"overwrite"}
                    label={t("Overwrite Stock")}
                    checked={overWrite}
                    onChange={handleCheckBox}
                  />
                  {overWrite && 
                    (<Typography
                      fontSize={14}
                      sx={{ color: 'red' }}
                    >
                      *{t("This action will erase the existing data.")}
                    </Typography>
                  )}
                </BoxDX>
              </GridDX>
            </>
          ) : (
            <GridDX
              item
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: 2, height: "100%" }}
            >
              <BoxDX
                display="flex"
                sx={{ width: 600, justifyContent: "space-between" }}
              >
                <TextFieldDX
                  name="errors"
                  label={t("Errors")}
                  value={errors}
                  multiline
                  readOnly
                />
              </BoxDX>
            </GridDX>
          )}
        </GridDX>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        {withImages && isSaving && (
          <>
            <Typography>{`${t(
              "uploaded"
            )} ${savedSKUs}/${rowCount}`}</Typography>
            <BoxDX sx={{ m: 1, width: 240 }}>
              <ProgressBarDX value={(savedSKUs / rowCount) * 100} />
            </BoxDX>
          </>
        )}
        <BoxDX display="flex" flexDirection="row">
          {errors === "" ? (
            <>
              <ButtonDX
                variant="outlined"
                sx={{ mx: 2, mb: { xs: 2, sm: "auto" }, width: 120 }}
                onClick={() => props.handleClose()}
              >
                {t("Cancel")}
              </ButtonDX>
              <LoadingButtonDX
                onClick={onSave}
                sx={{ width: 120 }}
                loading={isSaving}
                loadingPosition="end"
              >
                {t("Save")}
              </LoadingButtonDX>
            </>
          ) : (
            <ButtonDX
              disabled={isSaving}
              onClick={() => props.handleClose(true)}
            >
              {t("Close")}
            </ButtonDX>
          )}
        </BoxDX>
      </DialogActions>
    </Dialog>
  );
};

export default SKUBulkForm;
