import useSecureAPI from "../hooks/usesecureapi";

const useOutboundRequestService = () => {
  const secureAPI = useSecureAPI();


  const getOutboundRequests = async (tenantId: number | null = null) => {
    const url = tenantId ? `OutboundRequest?tenantId=${tenantId}` : `OutboundRequest`
    const response = await secureAPI.get(url);
    return response.data;
  };
  const getOutboundRequestsByStatus = async (tenantId: number | null = null, status: number) => {
    const url = tenantId ? `OutboundRequest/bystatus/${status}?tenantId=${tenantId}` : `OutboundRequest/bystatus/${status}`
    const response = await secureAPI.get(url);
    return response.data;
  };
  const getOutboundRequestById = async (id: number) => {
    const response = await secureAPI.get(`OutboundRequest/${id}`);
    return response.data;
  };

  const addOutboundRequest = async (data: any) => {
    const response = await secureAPI.post("OutboundRequest", data);
    return response.data;
  };

  const updateOutboundRequest = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest`, data);
    return response.data;
  };

  const archiveOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/archive/${id}`);
    return response.data;
  };

  const acceptOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/accept/${id}`);
    return response.data;
  };
  const declineOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/decline/${id}`);
    return response.data;
  };

  const processOutboundRequest = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/process`, data);
    return response.data;
  };


  const rejectModifications = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/modificationreject/${id}`);
    return response.data;
  }
  const approveModifications = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/modificationapprove/${id}`);
    return response.data;
  }


  const getPickerList = async () => {
    const response = await secureAPI.get("OutboundRequest/picker");
    return response.data;
  };

  const assignPicker = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/assignpicker`, data);
    return response.data;
  }


  const pickSku = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/picksku`, data);
    return response.data;
  }

  const markAsPicked = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/picked/${id}`);
    return response.data;
  }

  const packSku = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/packsku`, data);
    return response.data;
  }


  const unAssignPicker = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/unassignpicker/${id}`);
    return response.data;
  };

  const moveToQC = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/qc/${id}`);
    return response.data;
  }

  const markAsPacked = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/packed/${id}`);
    return response.data;
  }

  const moveToDispatched = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/dispatch/${id}`);
    return response.data;
  }

  const moveToSelfCollection = async ({  outboundRequestId, collectionDate }: { outboundRequestId: number, collectionDate: string }) => {
    const response = await secureAPI.put(`OutboundRequest/selfcollection`, { outboundRequestId,collectionDate });
    return response.data;
  }

  const selfCollection = async (data: any) => {
    const response = await secureAPI.put(`OutboundRequest/selfcollect`, data);
    return response.data;
  }

  
  const moveToOnHold = async ({  outboundRequestId, onHoldReason }: { outboundRequestId: number, onHoldReason: string }) => {
    const response = await secureAPI.put(`OutboundRequest/onhold`, { outboundRequestId,onHoldReason });
    return response.data;
  }

  const markAsCompleted = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/completed/${id}`);
    return response.data;
  }
  const moveToReturned = async ({  outboundRequestId, returnReason }: { outboundRequestId: number, returnReason: string }) => {
    const response = await secureAPI.put(`OutboundRequest/returned`, { outboundRequestId,returnReason });
    return response.data
  }

  const cancelOutboundRequest = async (id: number) => {
    const response = await secureAPI.put(`OutboundRequest/cancel/${id}`);
    return response.data;
  }



  return {
    getOutboundRequests,
    getOutboundRequestsByStatus,
    getOutboundRequestById,
    addOutboundRequest,
    updateOutboundRequest,
    archiveOutboundRequest,
    acceptOutboundRequest,
    declineOutboundRequest,
    processOutboundRequest,
    rejectModifications,
    approveModifications,
    getPickerList,
    assignPicker,
    unAssignPicker,
    pickSku,
    markAsPicked,
    packSku,
    moveToQC,
    markAsPacked,
    moveToDispatched,
    moveToSelfCollection,
    selfCollection,
    moveToOnHold,
    markAsCompleted,
    moveToReturned,
    cancelOutboundRequest

  };
};

export default useOutboundRequestService;



