import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import SkuForm from "../../components/units_forms/sku/skuform";
import useSKUService from "../../shared/services/skuservice";
import { useNotificationContext } from "../../context/notificationcontext";
import { useAuthContext } from "../../context/authcontext";
import SKUBulkForm from "../../components/units_forms/sku/skubulkform";
import useUserService from "../../shared/services/userservices";
import GridDX from "../../components/layout/griddx";
import BoxDX from "../../components/layout/boxdx";
import SelectListDX from "../../components/controls/selectlistdx";
import { PrintDocumentWithBarcode } from "../../shared/pdfoperations";
import { useTenantContext } from "../../context/tenantcontext";
import useCustomerService from "../../shared/services/customerservice";

const SKU = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { getCustomers } = useCustomerService();
  const { getSKUsForCustomer, deleteSKU } = useSKUService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [importForm, setImportForm] = useState(false);
  const [skuId, setSkuId] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: `${t("SKU Code")}`,
      // it has to be a number to be able to sort
      type: "number",
      align: "left"
    },
    {
      field: "name",
      headerName: `${t("Name")}`,
      minWidth: 200,
    },
    {
      field: "specification",
      headerName: `${t("Specification")}`,
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <div title={value}>
            {value?.length > 100 ? value?.substr(0, 100) + "..." : value}
          </div>
        );
      },
    },
  ];

  const getData = () => {
    const id = userData.userType === "C" ? userData.customerId : customerId;
    if (id) {
      setIsLoading(true);
      getSKUsForCustomer(id)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getCustomerList = async () => {
    const operation = userData.userType === "S" ? getCustomers(tenantId) : getCustomers();
    operation
      .then((res) => {
        setCustomers(
          res.map((user: any) => {
            return { text: user.fullname, value: user.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const onEdit = (data: any) => {
    setSkuId(data.skuId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toImport = () => {
    setImportForm(true);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    deleteSKU(id)
      .then(() => {
        setInfo(t("SKU deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setImportForm(false);
    setSkuId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.code?.toLowerCase()?.includes(value.toLowerCase()) ||
        row.name?.toLowerCase()?.includes(value.toLowerCase()) ||
        row.specification?.toLowerCase()?.includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setCustomerId(value);
  };

  useEffect(() => {
    userData.userType !== "C" && getCustomerList();
  }, [tenantId]);

  useEffect(() => {
    getData();
  }, [customerId]); 

  useEffect(() => {
    setDataFromApi(rows);
    getData();
    getCustomerList();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  return (
    <>
      {showForm && <SkuForm id={skuId} handleClose={onClose} />}
      {importForm && <SKUBulkForm open={importForm} handleClose={onClose} />}
      {!showForm && (
        <GridDX sx= {{ flexDirection: "column", alignItems: "flex-end", overflow: 'hidden'}} >
          {userData.userType !== "C" && (
            <GridDX
              item
              xs={12}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              mt={1}
            >
              <BoxDX width={350}>
                <SelectListDX
                  label={t("Select Customer")}
                  name="customerId"
                  items={customers}
                  value={customerId}
                  onChange={handleInputChange}
                />
              </BoxDX>
            </GridDX>
          )}
          <GridDX xs={12}>
            <ListPageDX
              listTitle={t("SKU")}
              name={t("SKU")}
              rows={rows}
              columns={columns}
              getRowId={(row: any) => row.skuId}
              isLoading={isLoading}
              onEdit={onEdit}
              onDelete={onDelete}
              editLabel={t("Preview")}
              setGridFilterCriteria={setGridFilterCriteria}
              exportToPDF={false}
              exportToCSV={false}
              showDatePicker={false}
              showSelect={false}
              showImportButton
              onImport={toImport}
              importTemplateLink={"../assets/templates/UnitsSKUTemplate.csv"}
              onPrint={(data: any) => PrintDocumentWithBarcode(`${data.code}`)}
            />
          </GridDX>
        </GridDX>
      )}
    </>
  );
};

export default SKU;
