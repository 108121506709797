import { useEffect, useState } from "react";
import { t } from "i18next";
import TextFieldDX from "../../controls/textfielddx";
import UploadBoxDX from "../../controls/uploadboxdx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import useSKUService from "../../../shared/services/skuservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import Loading from "../../layout/loading";
import CheckBoxDX from "../../controls/checkboxdx";
import useSupplierService from "../../../shared/services/supplierservice";
import useBrandService from "../../../shared/services/brandservice";
import useSkuCategoryService from "../../../shared/services/skucategoryservice";
import SelectListDX from "../../controls/selectlistdx";
import usePackingMaterialService from "../../../shared/services/packingmaterialservice";
import TypographyDX from "../../layout/typographydx";
import TypeTranslator from "../../../shared/typetranslator";
import { IconButton } from "@mui/material";
import { AddOutlined, Delete } from "@mui/icons-material";
import ButtonDX from "../../controls/buttondx";
import BoxDX from "../../layout/boxdx";
import useUserService from "../../../shared/services/userservices";
import DeleteModal from "../../alerts/deleteModal";
import useStorageTypeService from "../../../shared/services/storagetypeservice";
import { useTenantContext } from "../../../context/tenantcontext";
import useCustomerService from "../../../shared/services/customerservice";

const SkuForm = (props: any) => {
  const { id, handleClose } = props;
  const { getSKUById, addSKU, updateSKU, deleteSKUPicture } = useSKUService();
  const { getSuppliers } = useSupplierService();
  const { getBrands } = useBrandService();
  const { getSkuCategories } = useSkuCategoryService();
  const { getPackingMaterials } = usePackingMaterialService();
  const { getStorageTypes } = useStorageTypeService();
  const { getCustomers } = useCustomerService();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { unitTypes, mappingTypes } = TypeTranslator();
  const defaultData = {
    code: "",
    name: "",
    specification: "",
    isPerishable: false,
    supplierId: null,
    skuCategoryId: null,
    packingMaterialId: null,
    brandId: null,
    storageTypeId: null,
    uomTypeId: null,
    upcEanMapping: "",
    lengthInCm: 0,
    widthInCm: 0,
    heightInCm: 0,
    weightInGm: 0,
    serializedManagementTypeId: null,
    enableBatch: false,
    enableShelfLife: false,
    productShelfDays: 0,
    productShelfInboundDays: 0,
    productShelfOutboundDays: 0,
    enableStockTakeCycle: false,
    stockTakeCycleCountDays: 0,
    stockThreshold: 0,
    bufferStock: 0,
    replenishMinUnits: 0,
    replenishMaxUnits: 0,
    customerId: userData.customerId || props.customerId,
  };

  const defaultPictureData = [
    { picture: null, pictureURL: null, pictureId: 0 },
    { picture: null, pictureURL: null, pictureId: 0 },
    { picture: null, pictureURL: null, pictureId: 0 },
    { picture: null, pictureURL: null, pictureId: 0 },
  ];

  const defaultSkuMappings = {
    mappingTypeId: null,
    uomTypeId: null,
    upcEanMapping: "",
    numberOfUnits: 0,
    lengthInCm: 0,
    widthInCm: 0,
    heightInCm: 0,
    weightInGm: 0,
  };

  const [data, setData] = useState<any>(defaultData);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [materials, setMaterials] = useState<any>([]);
  const [storageTypes, setStorageTypes] = useState<any>([]);
  const [pictures, setPictures] = useState<any>(defaultPictureData);
  const [deleteImgModal, setDeleteImgModal] = useState<any>({ open: false, id: null });
  const [skuMappings, setSkuMappings] = useState<any>([]);
  const [updatedSkuMappings, setUpdatedSkuMappings] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleMappingInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setIsChanged(true);
    const newMappings = [...skuMappings];
    newMappings[index] = {
      ...newMappings[index],
      [name]: value,
    };
    setSkuMappings([...newMappings]);
  };

  const handleImageChange = async (newImage: any, index: number) => {
    if (newImage !== undefined) {
      const picsArray = pictures;
      const removedPic = picsArray.at(index);
      if (newImage === null && removedPic.pictureURL) {
        handleDeleteModalOpen(removedPic.pictureId);
      }
      picsArray.splice(index, 1, {
        picture: newImage,
        pictureURL: null,
      });
      setPictures([...picsArray]);
    }
  };

  const handleCustomerChange = (e: any) => {
    const { value } = e.target;
    setCustomerId(value);
  };

  const handleDeleteModalOpen = (id: any) => {
    setDeleteImgModal({ open: true, id });
  };

  const confirmDelete = () => {
    onDeletePicture(deleteImgModal.id);
    setDeleteImgModal({ open: false, id: null });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.code) newErrors["code"] = "Code is required";
    if (!data.name) newErrors["name"] = "Name is required";
    if (!customerId && !id && userData.userType === "S") newErrors["customerId"] = "Select a Customer";
    if (!data.storageTypeId) newErrors["storageTypeId"] = "Storage Type is required";
    if (!data.uomTypeId) newErrors["uomTypeId"] = "Required";
    if (!data.upcEanMapping) newErrors["upcEanMapping"] = "Required";
    if (data.weightInGm <= 0) newErrors["weightInGm"] = "Required";
    if (data.heightInCm <= 0) newErrors["height"] = "Required";
    if (data.widthInCm <= 0) newErrors["width"] = "Required";
    if (data.lengthInCm <= 0) newErrors["length"] = "Required";
    if (data.numOfUnits <= 0) newErrors["numOfUnits"] = "Required";
   
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getCustomerList = async () => {
    const operation = userData.userType === "S" ? getCustomers(tenantId) : getCustomers();
    operation
      .then((res) => {
        setCustomers(
          res.map((user: any) => {
            return { text: user.fullname, value: user.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const getData = async () => {
    const p1 = getBrands();
    const p2 = getSuppliers();
    const p3 = getSkuCategories();
    const p4 = getPackingMaterials();
    const p5 = getStorageTypes();

    setIsLoading(false);
    Promise.all([p1, p2, p3, p4, p5])
      .then(([brandList, supplierList, categoryList, materialList, storageList]) => {
        setBrands(
          brandList.map((brand: any) => ({
            text: brand.name,
            value: brand.brandId,
          }))
        );
        setSuppliers(
          supplierList.map((supplier: any) => ({
            text: supplier.name,
            value: supplier.supplierId,
          }))
        );
        setCategories(
          categoryList.map((category: any) => ({
            text: category.name,
            value: category.skuCategoryId,
          }))
        );
        setMaterials(
          materialList.map((material: any) => ({
            text: material.name,
            value: material.packingMaterialId,
          }))
        );
        setStorageTypes(storageList.map((type: any) => {
          return {
            text: type.name,
            value: type.storageTypeId
          };
        }));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDeletePicture = async (id: number) => {
    setIsLoading(true);
    deleteSKUPicture(id)
      .then((res) => {
        setInfo(t("Picture deleted successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);
      
      const saveData = {
        ...data,
        customerId: userData.userType !== "C" ? customerId : userData.customerId
      };

      const formData = new FormData();
      for (var key in saveData) {
        if (saveData[key] !== null) formData.append(key, saveData[key]);
      }

      pictures.forEach((picture: any, index: any) => {
        if (picture.picture !== null) {
          formData.append(`pictures`, picture.picture);
        }
      });

      if (id) {
        updatedSkuMappings.forEach((uSkuMap: any, index: any) => {
          for (const dataKey in uSkuMap)
            formData.append(
              `skuUomMappings[${index}][${dataKey}]`,
              uSkuMap[dataKey]
            );
        });
      }

      skuMappings.forEach((skuMap: any, index: any) => {
        if (skuMap?.skuUomMappingId === undefined) {
          for (const dataKey in skuMap)
            formData.append(
              `skuUomMappings[${index}][${dataKey}]`,
              skuMap[dataKey]
            );
        }
      });

      const operation = id ? updateSKU(formData) : addSKU(formData);
      operation
        .then((response) => {
          setInfo(
            id ? t("SKU updated successfully") : t("SKU added successfully")
          );
          if (props.customerId) {
            handleClose({ skuId: response?.skuId, name: response?.name });
          } else {
            handleClose(true);
          }
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    userData.userType !== "C" && getCustomerList();
  }, [tenantId]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSKUById(id)
        .then((res) => {
          setData(res);
          res.skuPictures.forEach((pic: any, index: any) => {
            const picsArray = pictures;
            picsArray[index] = {
              picture: null,
              pictureURL: pic.skuPictureURL,
              pictureId: pic.skuPictureId,
            };
            setPictures([...picsArray]);
          });
          setSkuMappings(res.skuUomMappings);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    } else {
      userData.userType !== "C" && getCustomerList();
    }
    getData();
  }, [id]);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("SKU Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading />}
      {deleteImgModal.open && (
        <DeleteModal
          open={deleteImgModal.open}
          deleteLabel={t("Delete")}
          name={'skuPicture'}
          onDeleteClick={confirmDelete}
          onCancelClick={() => setDeleteImgModal({ open: false, id: null })}
        />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2}>
        {userData.userType !== "C" && !id && (
          <GridDX
            item
            xs={12}
            sx={{
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <BoxDX width={350}>
              <SelectListDX
                label={t("Select Customer")}
                name="customerId"
                items={customers}
                value={customerId}
                onChange={handleCustomerChange}
                error={errors["customerId"]}
              />
            </BoxDX>
          </GridDX>
        )}
        <GridDX item xs={12}>
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("General")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Supplier")}
            items={suppliers}
            name="supplierId"
            value={data.supplierId + ""}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("SKU Code")}
            name="code"
            value={data?.code}
            onChange={handleInputChange}
            error={errors["code"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Brand")}
            items={brands}
            name="brandId"
            value={data?.brandId + ""}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("SKU Category")}
            items={categories}
            name="skuCategoryId"
            value={data?.skuCategoryId + ""}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Packing Material")}
            items={materials}
            name="packingMaterialId"
            value={data?.packingMaterialId + ""}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Specification")}
            name="specification"
            value={data?.specification}
            onChange={handleInputChange}
            multiline
            minRows={2}
            maxRows={4}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Storage Type")}
            items={storageTypes}
            name="storageTypeId"
            value={data.storageTypeId + ""}
            onChange={handleInputChange}
            error={errors["storageTypeId"]}
          />
        </GridDX>
        <GridDX item xs={8}>
          <CheckBoxDX
            name={"isPerishable"}
            label={t("Perishable")}
            checked={data?.isPerishable}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Base UOM")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={2}>
          <SelectListDX
            label={t("Unit")}
            items={unitTypes}
            name="uomTypeId"
            value={data.uomTypeId + ""}
            onChange={handleInputChange}
            error={errors["uomTypeId"]}
          />
        </GridDX>
        <GridDX item xs={12} md={2}>
          <TextFieldDX
            label={t("UPC/EAN - Mapping")}
            name="upcEanMapping"
            value={data?.upcEanMapping}
            onChange={handleInputChange}
            error={errors["upcEanMapping"]}
          />
        </GridDX>
        <GridDX item xs={12} md={2}>
          <TextFieldDX
            label={`${t("Height")} (cm)`}
            name="heightInCm"
            value={data?.heightInCm}
            onChange={handleInputChange}
            type="number"
            error={errors["height"]}
          />
        </GridDX>
        <GridDX item xs={12} md={2}>
          <TextFieldDX
            label={t(`${t("Width")} (cm)`)}
            name="widthInCm"
            value={data?.widthInCm}
            onChange={handleInputChange}
            type="number"
            error={errors["width"]}
          />
        </GridDX>
        <GridDX item xs={12} md={2}>
          <TextFieldDX
            label={t(`${t("Length")} (cm)`)}
            name="lengthInCm"
            value={data?.lengthInCm}
            onChange={handleInputChange}
            type="number"
            error={errors["length"]}
          />
        </GridDX>
        <GridDX item xs={12} md={2}>
          <TextFieldDX
            label={t(`${t("Weight")} (g)`)}
            name="weightInGm"
            value={data?.weightInGm}
            onChange={handleInputChange}
            type="number"
            error={errors["weightInGm"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("SKU/UOM Mapping")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={8} alignItems="center">
          <ButtonDX
            sx={{ maxWidth: 100 }}
            onClick={() => {
              const newMappings = [...skuMappings];
              newMappings.push(defaultSkuMappings);
              setSkuMappings([...newMappings]);
            }}
          >
            <AddOutlined /> {t("Add")}
          </ButtonDX>
        </GridDX>
        {skuMappings.map((skuMap: any, index: number) => (
          <>
            <GridDX item xs={12} md={4}>
              <SelectListDX
                label={t("Identifier Type")}
                items={mappingTypes}
                name="mappingTypeId"
                value={skuMap.mappingTypeId + ""}
                onChange={(e: any) => handleMappingInputChange(e, index)}
              />
            </GridDX>
            <GridDX item xs={12} md={1.5}>
              <SelectListDX
                label={t("Unit")}
                items={unitTypes}
                name="uomTypeId"
                value={skuMap.uomTypeId + ""}
                onChange={(e: any) => handleMappingInputChange(e, index)}
              />
            </GridDX>
            <GridDX item xs={12} md={2}>
              <TextFieldDX
                label={t("UPC/EAN - Mapping")}
                name="upcEanMapping"
                value={skuMap.upcEanMapping}
                onChange={(e: any) => handleMappingInputChange(e, index)}
              />
            </GridDX>
            <GridDX item xs={12} md={1}>
              <TextFieldDX
                label={t(t(`${t("Height")} (cm)`))}
                name="heightInCm"
                value={skuMap.heightInCm}
                onChange={(e: any) => handleMappingInputChange(e, index)}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={1}>
              <TextFieldDX
                label={t(t(`${t("Width")} (cm)`))}
                name="widthInCm"
                value={skuMap.widthInCm}
                onChange={(e: any) => handleMappingInputChange(e, index)}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={1}>
              <TextFieldDX
                label={t(`${t("Length")} (cm)`)}
                name="lengthInCm"
                value={skuMap.lengthInCm}
                onChange={(e: any) => handleMappingInputChange(e, index)}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={1}>
              <TextFieldDX
                label={t(`${t("Weight")} (g)`)}
                name="weightInGm"
                value={skuMap.weightInGm}
                onChange={(e: any) => handleMappingInputChange(e, index)}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={0.5}>
              <IconButton
                onClick={() => {
                  if (id && skuMap?.skuUomMappingId !== undefined) {
                    setUpdatedSkuMappings([
                      ...updatedSkuMappings,
                      {
                        ...skuMap,
                        isDeleted: true,
                      },
                    ]);
                  }
                  const newMappings = [...skuMappings];
                  newMappings.splice(index, 1);
                  setSkuMappings([...newMappings]);
                }}
              >
                <Delete />
              </IconButton>
            </GridDX>
          </>
        ))}
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Stock Information")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Threshold")}
            name="stockThreshold"
            value={data?.stockThreshold}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Buffer Stock")}
            name="bufferStock"
            value={data?.bufferStock}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Replenishment")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Min. No. Of Units")}
            name="replenishMinUnits"
            value={data?.replenishMinUnits}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Max No. Of Units")}
            name="replenishMaxUnits"
            value={data?.replenishMaxUnits}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Serialized Inventory Management")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Unique Serial Number")}
            checked={data?.serializedManagementTypeId === 1}
            onChange={(e: any) => {
              if (data.serializedManagementTypeId === 1)
                setData({
                  ...data,
                  serializedManagementTypeId: "",
                });
              else
                setData({
                  ...data,
                  serializedManagementTypeId: 1,
                });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <CheckBoxDX
            label={t("Serial/IMEI Number ")}
            checked={data?.serializedManagementTypeId === 2}
            onChange={(e: any) => {
              if (data.serializedManagementTypeId === 2)
                setData({
                  ...data,
                  serializedManagementTypeId: "",
                });
              else
                setData({
                  ...data,
                  serializedManagementTypeId: 2,
                });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Batch Management")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={8}>
          <CheckBoxDX
            label={t("Enabled")}
            checked={data?.enableBatch}
            onChange={(e: any) => {
              setData({
                ...data,
                enableBatch: !data.enableBatch,
              });
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Shelf Life")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={2}>
          <CheckBoxDX
            label={t("Enabled")}
            checked={data?.enableShelfLife}
            onChange={(e: any) => {
              setData({
                ...data,
                enableShelfLife: !data.enableShelfLife,
              });
            }}
          />
        </GridDX>
        {data?.enableShelfLife ? (
          <>
            <GridDX item xs={12} md={2}>
              <TextFieldDX
                label={t("Product (Days)")}
                name="productShelfDays"
                value={data?.productShelfDays}
                onChange={handleInputChange}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={2}>
              <TextFieldDX
                label={t("Inbound (Days)")}
                name="productShelfInboundDays"
                value={data?.productShelfInboundDays}
                onChange={handleInputChange}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={2}>
              <TextFieldDX
                label={t("Outbound (Days)")}
                name="productShelfOutboundDays"
                value={data?.productShelfOutboundDays}
                onChange={handleInputChange}
                type="number"
              />
            </GridDX>
          </>
        ) : (
          <GridDX item xs={12} md={6}></GridDX>
        )}
        <GridDX item xs={12} md={4} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Stock Take Cycle Count")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={2}>
          <CheckBoxDX
            label={t("Enabled")}
            checked={data?.enableStockTakeCycle}
            onChange={(e: any) => {
              setData({
                ...data,
                enableStockTakeCycle: !data.enableStockTakeCycle,
              });
            }}
          />
        </GridDX>
        {data?.enableStockTakeCycle ? (
          <>
            <GridDX item xs={12} md={2}>
              <TextFieldDX
                label={t("Days")}
                name="stockTakeCycleCountDays"
                value={data?.stockTakeCycleCountDays}
                onChange={handleInputChange}
                type="number"
              />
            </GridDX>
            <GridDX item xs={12} md={4}></GridDX>
          </>
        ) : (
          <GridDX item xs={12} md={6}></GridDX>
        )}
        <GridDX item xs={12} alignItems="center">
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }}>
            {t("Pictures")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={3} sx={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <UploadBoxDX
              sx={{ width: "270px", height: "250px" }}
              image={pictures[0].picture}
              imageUrl={pictures[0].pictureURL} // Pass the image URL to the component
              setImage={(newImage: any) => handleImageChange(newImage, 0)}
            />
          </div>
        </GridDX>
        <GridDX item xs={3} sx={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <UploadBoxDX
              sx={{ width: "270px", height: "250px" }}
              image={pictures[1].picture}
              imageUrl={pictures[1].pictureURL} // Pass the image URL to the component
              setImage={(newImage: any) => handleImageChange(newImage, 1)}
            />
          </div>
        </GridDX>
        <GridDX item xs={3} sx={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <UploadBoxDX
              sx={{ width: "270px", height: "250px" }}
              image={pictures[2].picture}
              imageUrl={pictures[2].pictureURL} // Pass the image URL to the component
              setImage={(newImage: any) => handleImageChange(newImage, 2)}
            />
          </div>
        </GridDX>
        <GridDX item xs={3} sx={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom: "10px" }}>
            <UploadBoxDX
              sx={{ width: "270px", height: "250px" }}
              image={pictures[3].picture}
              imageUrl={pictures[3].pictureURL} // Pass the image URL to the component
              setImage={(newImage: any) => handleImageChange(newImage, 3)}
            />
          </div>
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default SkuForm;
