import useSecureAPI from "../hooks/usesecureapi";

const useCustomerService = () => {
  const secureAPI = useSecureAPI();

  const getCustomers = async (tenantId: number | null = null) => {
    const url = tenantId ? `Customer?tenantId=${tenantId}` : `Customer`
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getCustomerById = async (id: number) => {
    const response = await secureAPI.get(`Customer/${id}`);
    return response.data;
  };

  const addCustomer = async (data: any) => {
    const response = await secureAPI.post("Customer", data);
    return response.data;
  };

  const updateCustomer = async (data: any) => {
    const response = await secureAPI.put(`Customer`, data);
    return response.data;
  };

  const deleteCustomer = async (id: number) => {
    const response = await secureAPI.delete(`Customer/${id}`);
    return response.data;
  };
  const archiveCustomer = async (id: number) => {
    const response = await secureAPI.put(`Customer/archive/${id}`);
    return response.data;
  };

  const deleteCustomerLogo = async (id: number) => {
    const response = await secureAPI.put(`Customer/deletelogo/${id}`);
    return response.data;
  };
  return {
    getCustomers,
    getCustomerById,
    addCustomer,
    updateCustomer,
    deleteCustomer,
    archiveCustomer,
    deleteCustomerLogo
  };
};

export default useCustomerService;
