import useSecureAPI from "../hooks/usesecureapi";

const useInboundRequestService = () => {
  const secureAPI = useSecureAPI();

  const getInboundRequests = async (tenantId: number | null = null) => {
    const url = tenantId ? `InboundRequest?tenantId=${tenantId}` : `InboundRequest`
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getInboundRequestsByStatus = async (tenantId: number | null = null, status: number) => {
    const url = tenantId ? `InboundRequest/bystatus/${status}?tenantId=${tenantId}` : `InboundRequest/bystatus/${status}`
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getInboundRequestById = async (id: number) => {
    const response = await secureAPI.get(`InboundRequest/${id}`);
    return response.data;
  };

  const addInboundRequest = async (data: any) => {
    const response = await secureAPI.post("InboundRequest", data);
    return response.data;
  };

  const updateInboundRequest = async (data: any) => {
    const response = await secureAPI.put(`InboundRequest`, data);
    return response.data;
  };

  const archiveInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/archive/${id}`);
    return response.data;
  };

  const acceptInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/accept/${id}`);
    return response.data;
  };
  const declineInboundRequest = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/decline/${id}`);
    return response.data;
  };

  const startCounting = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/startcounting/${id}`);
    return response.data;
  };

  const startQC = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/startqc/${id}`);
    return response.data;
  };
  
  const startPutAway = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/startputaway/${id}`);
    return response.data;
  };

  const markAsCompleted = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/markascompleted/${id}`);
    return response.data;
  };
 
  const processInboundRequestSKU = async (data: any) => {
    const response = await secureAPI.put(`InboundRequest/processSKU`, data);
    return response.data;
  };

  const createGRN = async (data: any) => {
    const response = await secureAPI.put(`InboundRequest/createGRN`, data);
    return response.data;
  };
  
  const getAllGRNs = async (tenantId: number | null = null) => {
    const url = tenantId ? `InboundRequest/allGRNs?tenantId=${tenantId}` : `InboundRequest/allGRNs`
    const response = await secureAPI.get(url);
    return response.data;
  };

  const rejectModifications = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/modificationreject/${id}`);
    return response.data;
  }
  const approveModifications = async (id: number) => {
    const response = await secureAPI.put(`InboundRequest/modificationapprove/${id}`);
    return response.data;
  }
  return {
    getInboundRequests,
    getInboundRequestsByStatus,
    getInboundRequestById,
    addInboundRequest,
    updateInboundRequest,
    archiveInboundRequest,
    processInboundRequestSKU,
    acceptInboundRequest,
    declineInboundRequest,
    rejectModifications,
    approveModifications,
    createGRN,
    getAllGRNs,
    startCounting,
    startQC,
    startPutAway,
    markAsCompleted
  };
};

export default useInboundRequestService;
