import { Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import BoxDX from "./boxdx";

const GaugeBoxDX = (props: any) => {
  const { title, value, valueMax } = props;

  const ratio = value / valueMax;

  const getColor = (val: number) => {
    if (val >= 80) return "#52C41A";
    else if (val >= 50) return "#FF8A36";
    else return "#F94839";
  };

  return (
    <BoxDX
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        backgroundColor: "#ffff",
        py: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <BoxDX display="flex" flexDirection="row" width="100%">
        <Typography fontSize={24} fontWeight="bold" sx={{ mx: 1 }}>
          {title}
        </Typography>
      </BoxDX>
      <BoxDX width="100%">
        <Gauge
          height={250}
          value={value}
          valueMax={valueMax}
          text={() => isNaN(ratio) ? '0%' : `${((ratio) * 100).toFixed(1)}%`}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 28,
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: getColor(isNaN(ratio) ? 0 : ((ratio) * 100)),
            },
          })}
        />
      </BoxDX>
      <BoxDX
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Typography fontSize={16} sx={{ mx: 1 }}>
          {`Used: ${value} CBM`}
        </Typography>
        <Typography fontSize={16} sx={{ mx: 1 }}>
          {`Free: ${valueMax - value} CBM`}
        </Typography>
      </BoxDX>
    </BoxDX>
  );
};

export default GaugeBoxDX;
