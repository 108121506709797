import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DATE_FORMAT, numberWithCommas } from "../../../shared/globals";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import BoxDX from "../../layout/boxdx";
import { useAuthContext } from "../../../context/authcontext";
import useWarehouseService from "../../../shared/services/warehouseservice";
import useCustomerAddressService from "../../../shared/services/customeraddressservice";
import ItemBox from "../../units_components/itembox";
import useSKUService from "../../../shared/services/skuservice";
import useUserService from "../../../shared/services/userservices";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import OutboundRequestItems from "../../units_components/outboundrequestitems";
import { InputAdornment, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TypeTranslator from "../../../shared/typetranslator";
import TypographyDX from "../../layout/typographydx";
import CheckBoxDX from "../../controls/checkboxdx";
import OutboundReport from "../../pages/reports/outboundrequest/outboundrequestreport";
import PickerModal from "./pickerlistmodal";
import { useTenantContext } from "../../../context/tenantcontext";
import useCustomerService from "../../../shared/services/customerservice";

const OutboundRequestForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestId = state?.requestId;
  const handleClose = () => {
    navigate(-1);
  };

  const { getSKUsForCustomer } = useSKUService();
  const { setError } = useNotificationContext();
  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();

  const {
    addOutboundRequest,
    updateOutboundRequest,
    getOutboundRequestById,
    acceptOutboundRequest,
    declineOutboundRequest,
    approveModifications,
    rejectModifications,
    processOutboundRequest,
  } = useOutboundRequestService();
  const { getCustomers } = useCustomerService();
  const { getCustomerAddressForCustomer } = useCustomerAddressService();
  const { getWarehouses } = useWarehouseService();

  const defaultValues = {
    customerId: userData.customerId,
    requestDate: moment(),
    outboundDeliveryTypeId: null,
    dropoffAddressId: null,
    warehouseId: null,
    scheduleDate: moment(),
    pocName: "",
    pocPhone: "",
    outboundRequestSkus: [],
    receiptNotes: null,
    carTypeId: null,
    numberOfCars: null,
    cargoTypeId: null,
    cargoSize: null,
  };

  const { cargoTypes, outboundDeliveryTypes, vehicleTypes } = TypeTranslator();
  const [requestData, setRequestData] = useState<any>(defaultValues);
  const [requestDataFromApi, setRequestDataFromApi] =
    useState<any>(defaultValues);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [showPickerList, setShowPickerList] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    if (name == "sameAsBillingAddress") {
      setRequestData({
        ...requestData,
        [name]: checked,
        shippingAddressId: checked ? requestData.dropoffAddressId : null,
        shippingPocName: checked ? requestData.pocName : "",
        shippingPocPhone: checked ? requestData.pocPhone : "",
      });
    }
    else {
      setRequestData({
        ...requestData,
        [name]: type == "checkbox" ? checked : value,
      });
    }
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!requestData.pocName.trim().length)
      newErrors["pocName"] = "Poc Name is required";
    if (!requestData.pocPhone) newErrors["pocPhone"] = "Poc Phone is required";
    if (!requestData.outboundDeliveryTypeId)
      newErrors["outboundDeliveryTypeId"] =
        "Outbound Delivery Type is required";
    if (!requestData.customerId)
      newErrors["customerId"] = "Customer is required";
    if (!requestData.dropoffAddressId)
      newErrors["dropoffAddressId"] = "Dropoff Address is required";
    if (!requestData.warehouseId)
      newErrors["warehouseId"] = "Warehouse is required";
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      if (newErrors["pocName"]) {
        pocNameRef.current?.focus();
      } else if (newErrors["pocPhone"]) {
        pocPhoneRef.current?.focus();
      }
      // Focus on more fields if needed

      return false; // Form is not valid
    }

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);

      let updatedData = {
        ...requestData,
        status: status == 2 ? 2 : 1,
      };
      // remove item_id from quotationItems to send to the server
      updatedData = {
        ...updatedData,
        outboundRequestSkus: requestData.outboundRequestSkus.map(
          (item: any) => {
            const { item_id, outbound_request_id, ...rest } = item;
            return rest;
          }
        ),
      };

      // pop those items out which dont have an skuId and but isDeleted flag
      updatedData = {
        ...updatedData,
        outboundRequestSkus: updatedData.outboundRequestSkus.filter(
          (item: any) => item.outboundRequestSkuId || !item.isDeleted
        ),
      };
      const operation = requestId ? updateOutboundRequest : addOutboundRequest;
      operation(updatedData)
        .then(() => {
          setInfo(
            requestId
              ? t("Request updated successfully")
              : t("Request added successfully")
          );
          handleClose();
        })
        .catch((error: any) => {
          setInfo(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (requestId) {
      setIsLoading(true);
      getOutboundRequestById(requestId)
        .then((response: any) => {
          setIsFormEditable(() => {
            // form is only editable if status is 1 (Draft)
            if (response.status === 1) {
              return true;
            }
            return false;
          });

          // setting just to check if any sku data is modified or new sku is added to the list
          setRequestDataFromApi(response);

          setRequestData({
            ...response,
            outboundRequestSkus: response.outboundRequestSkus.map(
              (item: any, index: number) => ({
                ...item,
                item_id: index + 1 * 1000,
              })
            ),
          });
        })
        .catch((error: any) => {
          setInfo(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  const [addresses, setAddresses] = useState<any>([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [customersList, setCustomersList] = useState<any>([]);
  const getDropDownData = () => {
    const p1 = userData?.customerId
      ? getCustomerAddressForCustomer(userData?.customerId)
      : null;
    const p2 = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    const p3 = userData?.userType !== "C" ? (userData?.userType === "S" ? getCustomers(tenantId) : getCustomers()) : null;
    Promise.all([p1, p2, p3]).then(([addresses, warehouses, users]) => {
      setAddresses(
        addresses?.map((address: any) => ({
          text: address.streetAddress,
          value: address.customerAddressId,
        }))
      );
      setWarehouses(
        warehouses.map((warehouse: any) => ({
          text: warehouse.name,
          value: warehouse.warehouseId,
        }))
      );
      setCustomersList(
        users
          ?.map((user: any) => ({
            text: user.fullname,
            value: user.customerId,
          }))
      );
    });
  };
  useEffect(() => {
    getDropDownData();
  }, [tenantId]);

  const [skus, setSKUs] = useState<any>([]);
  const [skuDropdownData, setSkuDropdownData] = useState<any>([]);

  const getSkuDropDownData = () => {
    if (userData.userType !== "C") {
      if (requestData?.customerId) {
        const p1 = getCustomerAddressForCustomer(requestData?.customerId);
        const p2 = getSKUsForCustomer(requestData?.customerId);

        Promise.all([p1, p2])
          .then(([addresses, skus]) => {
            setAddresses(
              addresses?.map((address: any) => ({
                text: address.streetAddress,
                value: address.customerAddressId,
              }))
            );
            setSKUs(skus);
            setSkuDropdownData(
              skus?.map((sku: any) => ({ text: sku.name, value: sku.skuId }))
            );
          })
          .catch((err) => setError(err));
      }
    } else {
      setIsLoading(true);
      getSKUsForCustomer(userData?.customerId)
        .then((data) => {
          setSKUs(data);
          setSkuDropdownData(
            data?.map((sku: any) => ({ text: sku.name, value: sku.skuId }))
          );
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getSkuDropDownData();
  }, [requestData?.customerId]);

  const handleAcceptOutboundRequest = () => {
    setIsLoading(true);
    acceptOutboundRequest(requestId)
      .then(() => {
        setInfo(t("Request accepted successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };
  const handleDeclineOutboundRequest = () => {
    setIsLoading(true);
    declineOutboundRequest(requestId)
      .then(() => {
        setInfo(t("Request rejected successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };

  const handleApproveModifications = () => {
    setIsLoading(true);
    approveModifications(requestId)
      .then(() => {
        setInfo(t("Modifications approved successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };
  const handleRejectModifications = () => {
    setIsLoading(true);
    rejectModifications(requestId)
      .then(() => {
        setInfo(t("Modifications rejected successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };

  const [isProcessInitiated, setIsProcessInitiated] = useState(false);
  const [isSkuDataModified, setIsSkuDataModified] = useState(false);

  // check if any sku data is modified or new sku is added to the list
  useEffect(() => {
    // remove item_id from quotationItems to send to the server
    let updatedData = {
      ...requestData,
      outboundRequestSkus: requestData.outboundRequestSkus.map((item: any) => {
        const { item_id, outbound_request_id, ...rest } = item;
        return rest;
      }),
    };

    // pop those items out which dont have an skuId and but isDeleted flag
    updatedData = {
      ...updatedData,
      outboundRequestSkus: updatedData.outboundRequestSkus.filter(
        (item: any) => item.outboundRequestSkuId || !item.isDeleted
      ),
    };
    // check if any sku data is modified or new sku is added to the list
    //
    if (
      JSON.stringify(updatedData.outboundRequestSkus) !==
      JSON.stringify(requestDataFromApi.outboundRequestSkus)
    ) {
      let isModified = false;
      for (let i = 0; i < updatedData.outboundRequestSkus.length; i++) {
        if (
          updatedData.outboundRequestSkus.length !=
          requestDataFromApi.outboundRequestSkus.length
        ) {
          isModified = true;
          break;
        }
        // we have to check for another condition if quantity and received quantity are same then we should not consider it as modified
        if (
          updatedData.outboundRequestSkus[i].quantityReceived != null &&
          updatedData.outboundRequestSkus[i].quantityReceived !=
          requestDataFromApi.outboundRequestSkus[i].quantity
        ) {
          isModified = true;
          break;
        }
      }
      if (isModified) {
        setIsSkuDataModified(true);
      } else {
        setIsSkuDataModified(false);
      }
    } else {
      // if no sku data is modified or new sku is added to the list
      setIsSkuDataModified(false);
    }
  }, [requestData.outboundRequestSkus]);
  const initiateProcess = () => {
    setIsProcessInitiated(true);
    setShowPickerList(true);
  };

  const handleProcessOutboundRequest = (status: any) => {
    setIsLoading(true);
    let updatedData = {
      outboundRequestId: requestData.outboundRequestId,
      status: status,
      receiptNotes: requestData.receiptNotes,
      outboundRequestSkus: requestData.outboundRequestSkus.map((item: any) => {
        const { item_id, outbound_request_id, ...rest } = item;
        return rest;
      }),
    };

    // pop those items out which dont have an skuId and but isDeleted flag
    updatedData = {
      ...updatedData,
      outboundRequestSkus: updatedData.outboundRequestSkus.filter(
        (item: any) => item.outboundRequestSkuId || !item.isDeleted
      ),
    };

    processOutboundRequest(updatedData)
      .then(() => {
        setInfo(t("Request processed successfully"));
        handleClose();
      })
      .catch((error: any) => {
        setInfo(error);
      })
      .finally(() => setIsLoading(false));
  };

  // requestData.inboundDeliveryTypeId != 2 set carTypeId and numberOfCars to null
  useEffect(() => {
    if (requestData.inboundDeliveryTypeId != 2) {
      setRequestData({
        ...requestData,
        carTypeId: null,
        numberOfCars: null,
      });
    }
  }, [requestData.inboundDeliveryTypeId]);
  const [showPreview, setShowPreview] = useState(false);
  return (
    <BoxDX sx={{ width: "100%" }}>
      {showPreview && (
        <OutboundReport
          open={showPreview}
          onClose={() => setShowPreview(false)}
          requestData={{
            ...requestData,
            warehouseName: warehouses.find(
              (w: any) => w.value == requestData.warehouseId
            )?.text,
            customerName: customersList.find(
              (c: any) => c.value == requestData.customerId
            )?.text,
            cartype: vehicleTypes.find(
              (v: any) => v.value == requestData.carTypeId
            )?.text,
            cargoType: cargoTypes.find(
              (c: any) => c.value == requestData.cargoTypeId
            )?.text,
            dropoffAddress: addresses.find(
              (a: any) => a.value == requestData.dropoffAddressId
            )?.text,
            outboundDeliveryType: outboundDeliveryTypes.find(
              (o: any) => o.value == requestData.outboundDeliveryTypeId
            )?.text,
          }}
          skus={skus}
        />
      )}
      {showPickerList && (
        <PickerModal
          open={showPickerList}
          handleClose={(refresh: boolean) => {
            setShowPickerList(false);
            if (refresh) {
              getData();
            }
          }}
          outboundRequestId={requestId}
        />
      )}
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Outbound Request Details")}
        onSaveClick={onSave}
        onYesClick={handleClose}
        isPrintable={true}
        handleGeneratePDF={() => setShowPreview(true)}
        // showSaveAndSubmit={true}
        showSaveAndSubmit={
          (requestData.status == 1 || !requestData.status) && true
        }
        outboundRequestStatus={requestData.status}
        onAcceptClick={handleAcceptOutboundRequest}
        onDeclineClick={handleDeclineOutboundRequest}
        onApproveModifications={handleApproveModifications}
        onRejectModifications={handleRejectModifications}
        initiateProcess={initiateProcess}
        onProcessClick={handleProcessOutboundRequest}
        isProcessInitiated={isProcessInitiated}
        isSkuDataModified={isSkuDataModified}
      >
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled
            label={t("Request Date")}
            name="requestDate"
            value={moment(requestData.requestDate)}
            onChange={(value: any) => handleDateChange(value, "requestDate")}
            error={errors["requestDate"]}
            minDate={moment()}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled={!isFormEditable}
            label={t("Schedule Date")}
            name="scheduleDate"
            value={moment(requestData.scheduleDate)}
            onChange={(value: any) => handleDateChange(value, "scheduleDate")}
            error={errors["scheduleDate"]}
            minDate={requestData.requestDate}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Outbound Delivery Type")}
            name="outboundDeliveryTypeId"
            value={requestData.outboundDeliveryTypeId}
            onChange={handleInputChange}
            error={errors["outboundDeliveryTypeId"]}
            items={outboundDeliveryTypes}
            InputLabelProps={{
              shrink: requestData.outboundDeliveryTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        {userData.userType !== "C" ? (
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Customer")}
              name="customerId"
              value={requestData.customerId}
              onChange={handleInputChange}
              error={errors["customerId"]}
              items={customersList}
              InputLabelProps={{
                shrink: requestData.customerId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        ) : (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled
              label={t("Customer")}
              value={userData.fullName}
            />
          </GridDX>
        )}

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Warehouse")}
            name="warehouseId"
            value={requestData.warehouseId}
            onChange={handleInputChange}
            error={errors["warehouseId"]}
            items={warehouses}
            InputLabelProps={{
              shrink: requestData.warehouseId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        {requestData.outboundDeliveryTypeId == 2 && (
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Type Of Car")}
              name="carTypeId"
              value={requestData.carTypeId}
              onChange={handleInputChange}
              error={errors["carTypeId"]}
              items={vehicleTypes}
              InputLabelProps={{
                shrink: requestData.carTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
        )}
        {requestData.outboundDeliveryTypeId == 2 && (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Number Of Cars")}
              name="numberOfCars"
              value={requestData.numberOfCars}
              onChange={handleInputChange}
              error={errors["numberOfCars"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
              InputProps={{
                inputProps: { min: 1 },
              }}
            />
          </GridDX>
        )}

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Type Of Cargo")}
            name="cargoTypeId"
            value={requestData.cargoTypeId}
            onChange={handleInputChange}
            error={errors["cargoTypeId"]}
            items={cargoTypes}
            InputLabelProps={{
              shrink: requestData.cargoTypeId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Cargo Size")}
            name="cargoSize"
            value={requestData.cargoSize}
            onChange={handleInputChange}
            error={errors["cargoSize"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
            InputProps={{
              inputProps: { min: 1 },
              endAdornment: <InputAdornment position="end">CBM</InputAdornment>,
            }}
          />
        </GridDX>

        {((userData.userType === "S" &&
          requestData.status === 3 &&
          isProcessInitiated) ||
          requestData.status === 5) && (
            <GridDX item xs={12}>
              <TextFieldDX
                disabled={requestData.status == 5}
                label={t("Receipt Notes")}
                name="receiptNotes"
                value={requestData.receiptNotes}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </GridDX>
          )}
        <GridDX item xs={12}>
          <TypographyDX variant="h6">Billing Address</TypographyDX>
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Billing Address")}
            name="dropoffAddressId"
            value={requestData.dropoffAddressId}
            onChange={handleInputChange}
            error={errors["dropoffAddressId"]}
            items={addresses}
            InputLabelProps={{
              shrink: requestData.dropoffAddressId !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled={!isFormEditable}
            ref={pocNameRef}
            label={t("POC Name")}
            name="pocName"
            value={requestData.pocName}
            onChange={handleInputChange}
            error={errors["pocName"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled={!isFormEditable}
            ref={pocPhoneRef}
            label={t("POC Phone")}
            name="pocPhone"
            value={requestData.pocPhone}
            onChange={handleInputChange}
            error={errors["pocPhone"]}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>

        <GridDX
          item
          xs={12}
          sx={{
            display: "flex",
            gap: 20,
            alignItems: "center",
          }}
        >
          <TypographyDX variant="h6">Shipping Address</TypographyDX>
          <CheckBoxDX
            disabled={!isFormEditable}
            name="sameAsBillingAddress"
            label={t("Same as Billing Address")}
            checked={requestData.sameAsBillingAddress}
            onChange={handleInputChange}
          />
        </GridDX>
        {!requestData.sameAsBillingAddress && (
          <>
            <GridDX item xs={12}>
              <SelectListDX
                disabled={!isFormEditable}
                label={t("Shipping Address")}
                name="shippingAddressId"
                value={requestData.shippingAddressId}
                onChange={handleInputChange}
                error={errors["shippingAddressId"]}
                items={addresses}
                InputLabelProps={{
                  shrink: requestData.shippingAddressId !== null, // Set to true if there's a value just to handle label position
                }}
              />
            </GridDX>
            <GridDX item xs={12} md={6}>
              <TextFieldDX
                disabled={!isFormEditable}

                label={t("Shipping POC Name")}
                name="shippingPocName"
                value={requestData.shippingPocName}
                onChange={handleInputChange}
                error={errors["shippingPocName"]}
              />
            </GridDX>

            <GridDX item xs={12} md={6}>
              <TextFieldDX
                disabled={!isFormEditable}

                label={t("Shipping POC Phone")}
                name="shippingPocPhone"
                value={requestData.shippingPocPhone}
                onChange={handleInputChange}
                error={errors["shippingPocPhone"]}
                type="number"
                // Disable spin buttons for number input
                className="noSpinButtons"
              />
            </GridDX>
          </>
        )}

        <GridDX item xs={8}>
          {/* <CheckBoxDX
            disabled={!isFormEditable}
            label={t("VAT Included (15 %)")}
            name="vatPercent"
            checked={requestData.vatPercent !== 0}
            onChange={(e: any) => {
              e.target.checked
                ? setRequestData({ ...requestData, vatPercent: 15 })
                : setRequestData({ ...requestData, vatPercent: 0 });
            }}
          /> */}
        </GridDX>
        <GridDX item xs={4}>
          <GridDX container spacing={1}>
            <GridDX
              item
              xs={12}
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TypographyDX variant="subtitle1">Shipping Cost :</TypographyDX>
              <BoxDX
                sx={{ display: "flex", justifyContent: "flex-end" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {isHovered ? (
                  <>
                    <TextField
                      sx={{
                        width: "100px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                      inputProps={{
                        style: { textAlign: "right", padding: 2 },
                      }}
                      disabled={!isFormEditable}
                      value={requestData.shippingCost}
                      onChange={(e) =>
                        setRequestData({
                          ...requestData,
                          shippingCost: e.target.value,
                        })
                      }
                      type="number"
                      // Disable spin buttons for number input
                      className="noSpinButtons"
                    />{" "}
                    SAR
                  </>
                ) : (
                  <TypographyDX variant="subtitle1" fontWeight="bold">
                    {numberWithCommas(requestData.shippingCost || 0)} SAR
                  </TypographyDX>
                )}
              </BoxDX>
            </GridDX>

            <GridDX
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <TypographyDX variant="subtitle1">Total Amount :</TypographyDX>
              <TypographyDX variant="subtitle1" fontWeight="bold">
                {numberWithCommas(requestData.shippingCost || 0)} SAR
              </TypographyDX>
            </GridDX>
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>

      {requestData.customerId && (
        <ItemBox>
          <OutboundRequestItems
            requestData={requestData}
            setRequestData={setRequestData}
            getData={getData}
            setIsChanged={setIsChanged}
            skus={skus}
            skuDropdownData={skuDropdownData}
            getSkuDropDownData={getSkuDropDownData}
            isFormEditable={isFormEditable}
            isProcessInitiated={isProcessInitiated}
          />
        </ItemBox>
      )}
    </BoxDX>
  );
};

export default OutboundRequestForm;
