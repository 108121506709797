import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../context/notificationcontext";
import TextFieldDX from "../controls/textfielddx";
import GridDX from "../layout/griddx";
import AddEditEmbeddedModal from "../units_controls/addeditembeddedmodal";
import useWarehouseTimeslotService from "../../shared/services/warehousetimseslotservice";
import Loading from "../layout/loading";
import UnitsTimePicker from "../units_controls/units_timepickerdx";
import moment from "moment";
import SelectListDX from "../controls/selectlistdx";
import useWarehouseService from "../../shared/services/warehouseservice";
import { dateWithTime } from "../../shared/globals";
import { useAuthContext } from "../../context/authcontext";
import { useTenantContext } from "../../context/tenantcontext";

const WarehouseTimeslotForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const timeslotId = state?.timeslotId;
  const handleClose = () => {
    navigate(-1);
  };
  const { userData } = useAuthContext();
  const { tenantId } = useTenantContext();
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const {  getWarehouseTimeslotById, addWarehouseTimeslot, updateWarehouseTimeslot } = useWarehouseTimeslotService();

  const defaultData = {
    name: "",
    startTime: null,
    endTime: null,
    warehouseId: "",
  };

  const [data, setData] = useState<any>(defaultData);
  const [warehouses, setWarehouses] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const saveData = {
        ...data,
        startTime: moment(data.startTime).format("HH:mm").split(' ')[0],
        endTime: moment(data.endTime).format("HH:mm").split(' ')[0]
      };
      const operation = timeslotId
        ? updateWarehouseTimeslot(saveData)
        : addWarehouseTimeslot(saveData);
      operation
        .then(() => {
          setInfo(
            timeslotId
              ? t("Timeslot updated successfully")
              : t("Timeslot added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getWarehouseList = async () => {
    const operation = userData?.userType === "S" ? getWarehouses(tenantId) : getWarehouses();
    operation
      .then((res) => {
        setWarehouses(
          res.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
      })
      .catch((err) => setError(err));
  };
  
  useEffect(() => {
    if (timeslotId){
      setIsLoading(true);
      getWarehouseTimeslotById(timeslotId)
        .then((res) => {
          setData({
            ...res,
            startTime: dateWithTime(res.startTime),
            endTime: dateWithTime(res.endTime)
          });
          getWarehouseList();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else getWarehouseList();
  }, [tenantId]);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Timeslot Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading sx={{ width: "100%", height: "100%"}} />}
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
              label={t("Select Warehouse")}
              name="warehouseId"
              items={warehouses}
              value={data.warehouseId}
              onChange={handleInputChange}
            />
        </GridDX>
        <GridDX item xs={0} md={4}></GridDX>
        <GridDX item xs={12} md={4}>
          <UnitsTimePicker
            label={t("Start Time")}
            name="startTime"
            value={data.startTime}
            onChange={(value: any) => handleDateChange(value, "startTime")}
            // error={errors["dueDate"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <UnitsTimePicker
            label={t("End Time")}
            name="endTime"
            value={data.endTime}
            onChange={(value: any) => handleDateChange(value, "endTime")}
            // error={errors["dueDate"]}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default WarehouseTimeslotForm;
