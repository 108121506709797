import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseService = () => {
  const secureAPI = useSecureAPI();

  const getWarehouses = async (tenantId: number | null = null) => {
    const url = tenantId ? `Warehouse?tenantId=${tenantId}` : `Warehouse`
    const response = await secureAPI.get(url);
    return response.data;
  };

  const getWarehouseById = async (id: number) => {
    const response = await secureAPI.get(`Warehouse/${id}`);
    return response.data;
  };

  const addWarehouse = async (data: any) => {
    const response = await secureAPI.post("Warehouse", data);
    return response.data;
  };

  const updateWarehouse = async (data: any) => {
    const response = await secureAPI.put(`Warehouse`, data);
    return response.data;
  };

  const archiveWarehouse = async (id: number) => {
    const response = await secureAPI.put(`Warehouse/archive/${id}`);
    return response.data;
  };

  return {
    getWarehouses,
    getWarehouseById,
    addWarehouse,
    updateWarehouse,
    archiveWarehouse,
  };
};

export default useWarehouseService;
