import { createContext, useContext, useState, ReactNode, useEffect } from "react";

const TenantContext = createContext<any | null>(null);
const useTenantContext = () => useContext(TenantContext);

interface AuxProps {
  children: ReactNode;
}

const TenantContextProvider = ({ children }: AuxProps) => {
  const [tenantId, setTenantId] = useState<number | null>(null);

  const updateTenant = (id: number | null) => {
    if (id) {
      localStorage.setItem(
        "selectedTenant",
        id.toString() 
      );
      setTenantId(id);
    }
  }

  useEffect(() => {
    const lastUsedTenant = localStorage.getItem("selectedTenant");
    if (lastUsedTenant) setTenantId(parseInt(lastUsedTenant));
  }, [])

  return (
    <TenantContext.Provider value={{ tenantId, updateTenant }}>
      {children}
    </TenantContext.Provider>
  );
};

export { TenantContextProvider, useTenantContext };
